import React from 'react';
import './socials.css'

function Socials() {
  return (
    <div className="socialContainer">
      <div className="socialButton">
        <a href="https://twitter.com/keyanfayaz" className="fa fa-twitter"></a>
      </div>
      <div className="socialButton">
        <a href="https://linkedin.com/in/k-fayaz" className="fa fa-linkedin"></a>
      </div>
      <div className="socialButton">
        <a href="https://github.com/keyanfayaz" className="fa fa-github"></a>
      </div>
      {/* <div className="socialButton">
        <a href="https://keyanfayaz.medium.com" className="fa fa-medium"></a>
      </div> */}
      {/* <div className="socialButton">
        <a href="https://fantastical.app/kfayaz/meet-with-keyan" className="fa fa-calendar"></a>
      </div> */}

    </div>

  )
}

export default Socials;
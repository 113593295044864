import React from 'react';
// import PopupImage from '../utils/PopupImage'
// import arlo from './Arlo.jpg'

import './section.css'

export default function Intro() {
  return (
    <section className='headingMd'>
      <div>
        <h4></h4>
        <ul>
          <li>Experience building robotics, AI and IoT solutions</li>
          <li>Currently doing R&amp;D at Abalta Technologies <a href='https://abaltatech.com/' rel=''>→</a></li>

          {/* <li>Working with Python and Flutter</li>

          <li>Looking for ways to help people so I made <a href='/'>this account/channel</a></li> */}

          <li>Passionate about tech, productivity and enhancing user experience</li> {/*optimization*/}

          <li>Writing about productivity and workflows (soon) <a href="https://medium.com/@keyf" target="_blank">→</a></li>

          {/* <li>I'm constantly looking for ways to add value, so if you think I can do so for your team, feel free to get in touch via email using the button:</li> */}
          {/* <li className='sidebyside'>Picture of my dog </li> <PopupImage photo={arlo} /> */}

        </ul>
      </div>

      <div className='frame'>
        <a href='mailto:k_fayaz@outlook.com' className='my-b'>Say hello 👋</a>
      </div>
    </section >
  )
};
import React from 'react';
import { Link } from 'react-router-dom';
import Src from '../assets/keyan.png'
import Socials from '../utils/socials'

import '../index.css'

const name = "Hi, I'm Keyan"
const jobTitle = "Software Engineer"
const subtitle = "Technology 🤝 Experiences"

function Layout({ children, home }) {
    return (
        <div className='container'>
            <header className='header'>
                {home ? (
                    <>
                        <img
                            priority
                            src={Src}
                            height={144}
                            width={144}
                            alt={name}
                        />
                        <h1
                            className='heading2Xl'>
                            {name}
                        </h1>
                        <h3 className='headingMd'>{jobTitle}</h3>
                        <p className='subtitle'>{subtitle}</p>
                        <Socials />
                    </>
                ) : (
                    <>
                        <a href='/'>
                            <a>
                                <img
                                    priority
                                    src={Src}
                                    height={108}
                                    width={108}
                                    alt={name}
                                />
                            </a>
                        </a>
                        <h2 className='headingLg'>
                            <a href='/'>
                                <a className='colorInherit'>{name}</a>
                            </a>
                        </h2>
                        <h3 className='headingMd'>{jobTitle}</h3>
                    </>
                )}
            </header>
            {!home && (
                <div className={'backToHome'}>
                    <Link href="/">
                        <a>← Back to home</a>
                    </Link>
                </div>
            )}
            <main>
                {children}
            </main>
        </div>

    );
};

export default Layout;

